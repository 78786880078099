import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ruby-introduction",
  "date": "2015-01-16",
  "title": "RUBY – INTRODUCTION",
  "author": "admin",
  "tags": ["development", "ruby"],
  "featuredImage": "feature.jpg",
  "excerpt": "Ruby is a dynamic, reflective, object-oriented, general-purpose programming language. It was designed and developed in the mid-1990s by Yukihiro “Matz” Matsumoto in Japan."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Variables`}</h2>
    <p>{`Any plain, lowercase word is a variable in ruby. Variables may consist of letters, digits and underscores.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`x , y , banana2
`}</code></pre>
    <p>{`Variables are like nicknames, you give a nickname to something you use frequently. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`phone_price = 455.00
total = phone_price + case_price + delivery
`}</code></pre>
    <h2>{`Numbers`}</h2>
    <p>{`The most basic type of number is an integer, a series of digits which can start with a plus or minus sign.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`1, 23 , and -10000
`}</code></pre>
    <p>{`Commas are not allowed in numbers, but underscores are. So if you feel the need to mark your thousands so the numbers are more readable, use an underscore.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`population = 12_000_000_000
`}</code></pre>
    <p>{`Decimal numbers are called floats in Ruby. Floats consist of numbers with a decimal place or scientific notation.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`3.14 , -808.08 and 12.043e-04
`}</code></pre>
    <h2>{`Strings`}</h2>
    <p>{`Strings are any sort of characters (letters, digits, punctuation) surrounded by quotes. Both single and double quotes are used to create strings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`"sealab" , '2021' , or "These comments are hilarious!"
`}</code></pre>
    <p>{`When you enclose characters in quotes, they are stored together as a single string.`}</p>
    <h2>{`Symbols`}</h2>
    <p>{`Symbols are words that look just like variables. Again, they may contain letters, digits, or underscores. But they start with a colon.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`:a , :b , or :ponce_de_leon
`}</code></pre>
    <p>{`Symbols are lightweight strings. Usually, symbols are used in situations where you need a string but you won’t be printing it to the screen.`}</p>
    <h2>{`Constants`}</h2>
    <p>{`Constants are words like variables, but constants are capitalized. If variables are the nouns of Ruby, then think of constants as the proper nouns, and most importantly cannot be changed.`}</p>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`Time , Array or Bunny_Lake_is_Missing
`}</code></pre>
    <h2>{`Methods`}</h2>
    <p>{`If variables and constants are the nouns, then methods are the verbs. Methods are usually attached to the end of variables and constants by a dot. You’ve already seen methods at work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`front_door.open
`}</code></pre>
    <p>{`In the above, open is the method. It is the action, the verb. In some cases, you’ll see actions chained together.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`front_door.open.close
`}</code></pre>
    <p>{`We’ve instructed the computer to open the front door and then immediately close it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`front_door.is_open?
`}</code></pre>
    <p>{`The above is an action as well. We’re instructing the computer to test the door to see if it’s open. The method could be called Door.test_to_see_if_its_open, but the is_open? name is succinct and just as correct. Both exclamation marks and question marks may be used in method names.`}</p>
    <h2>{`Method arguments`}</h2>
    <p>{`A method may require more information to perform its action. If we want the computer to paint the door, we shouldprovide a colour as well. Method arguments are attached to the end of a method. The arguments are usually surrounded by parentheses and separated by commas.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`front_door.paint( 3, :red )
`}</code></pre>
    <p>{`The above paints the front door 3 coats of red.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`front_door.paint( 3, :red ).dry( 30 ).close()
`}</code></pre>
    <p>{`The above paints the front door 3 coats of red, dries for 30 minutes and closes the door. Even though the last method has no arguments, you can still put parentheses if you like. There is no use dragging an empty inner tube, so the parentheses are normally dropped.`}</p>
    <p>{`Some methods (such as print) are kernel methods. These methods are used throughout Ruby. Since they are so common, you won’t use the dot.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`print "See, no dot."
`}</code></pre>
    <h2>{`Class methods`}</h2>
    <p>{`Like the methods described above (also called instance methods), class methods are usually attached after variables and constants. Rather than a dot, a double colon is used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`Door::new( :oak )
`}</code></pre>
    <p>{`As seen above, the new class method is most often used to create things.`}</p>
    <h2>{`Global variables`}</h2>
    <p>{`Variables which begin with a dollar sign are global.`}</p>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <p><inlineCode parentName="p">{`ruby$x , $1 , $chunky and $CHunKY_bACOn`}</inlineCode></p>
    <p>{`Global variables can be used anywhere in your program. They never go out of sight.`}</p>
    <h2>{`Instance variables`}</h2>
    <p>{`Variables which begin with an at symbol are instance variables`}</p>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`@x , @y , and @only_the_chunkiest_cut_of_bacon_I_have_ever_seen
`}</code></pre>
    <p>{`These variables are often used to define the attributes of something. For example, you might provide Ruby with the width of
the front_door by setting the @width variable inside that front_door. Instance variables are used to
define characteristics of a single object in Ruby.`}</p>
    <p>{`Think of the at symbol as meaning attribute.`}</p>
    <h2>{`Class variables`}</h2>
    <p>{`Variables which begin with double at symbols are class variables.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`@@x , @@y , and @@i_will_take_your_chunky_bacon_and_raise_you_two
`}</code></pre>
    <p>{`Class variables, too, are used to define attributes. But rather than defining an attribute for a single object in Ruby, class variables give an attribute to many related objects in Ruby. If instance variables set attributes for a single front_door, then class variables set attributes for everything that is a Door.`}</p>
    <p>{`Think of the double at prefix as meaning attribute all. `}</p>
    <p>{`You change a class variable and not just one changes, they all change.`}</p>
    <h2>{`Blocks`}</h2>
    <p>{`Any code surrounded by curly braces is a block.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`2.times { print "Yes, I've used chunky bacon in my examples, but never again!" }
`}</code></pre>
    <p>{`With blocks, you can group a set of instructions so that they can be passed around your program.`}</p>
    <h2>{`Block arguments`}</h2>
    <p>{`Block arguments are a set of variables surrounded by pipe characters and separated by commas.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`|x| , |x,y| , and |up, down, all_around|
`}</code></pre>
    <p>{`Block arguments are used at the beginning of a block.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`{ |x,y| x + y }
`}</code></pre>
    <p>{`In the above example, |x,y| are the arguments. After the arguments, we have a bit of code. The expression x + y adds the two arguments together.`}</p>
    <h2>{`Ranges`}</h2>
    <p>{`A range is two values surrounded by parentheses and separated by an ellipsis (in the form of two or three dots).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`(1..3) is a range, representing the numbers 1 through 3.
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`('a'..'z') is a range, representing a lowercase alphabet.
`}</code></pre>
    <p>{`Normally, only two dots are used. If a third dot is used, the last value in the range is excluded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`(0...5) represents the numbers 0 through 4.
`}</code></pre>
    <h2>{`Arrays`}</h2>
    <p>{`An array is a list surrounded by square brackets and separated by commas.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`[1, 2, 3] is an array of numbers.
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`['coat', 'mittens', 'snowboard'] is an array of strings.
`}</code></pre>
    <h2>{`Hashes`}</h2>
    <p>{`A hash is a dictionary surrounded by curly braces. Dictionaries match words with their definitions. Ruby does so with arrows made from an equals sign, followed by a greater-than sign.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`{'a' => 'aardvark', 'b' => 'badger'}
`}</code></pre>
    <h2>{`Regular Expressions`}</h2>
    <p>{`A regular expression (or regexp) is a set of characters surrounded by slashes.`}</p>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`/ruby/ , /[0-9]+/ and /^\\d{3}-\\d{3}-\\d{4}/
`}</code></pre>
    <p>{`Regular expressions are used to find words or patterns in text. The slashes on each side of the expression are pins.`}</p>
    <h2>{`Operators`}</h2>
    <p>{`You’ll use the following list of operators to do math in Ruby or to compare things. Scan over the list, recognize a few. You know, addition + and subtraction - and so on.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`** ! ~ * / % + - &
 << >> | ^ > >= < <= <=>
 || != =~ !~ && += -= == ===
 .. ... not and or 
`}</code></pre>
    <h2>{`Keywords`}</h2>
    <p>{`Ruby has several built-in words, imbued with meaning. These words cannot be used as variables or changed to suit your purposes. Some of these we’ve already discussed. They are in the safe house, my friend. You touch these and you’ll be served an official syntax error.`}</p>
    <p><strong parentName="p">{`
alias and BEGIN begin break case class def defined
do else elsif END end ensure false for if
in module next nil not or redo rescue retry
return self super then true undef unless until when
while yield
`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      